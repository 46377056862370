import gql from 'graphql-tag';

export default gql`
  mutation trackSearch(
    $query: String!
    $resultCount: Int!
    $type: String
    $phase: String
  ) {
    trackSearch(
      query: $query
      resultCount: $resultCount
      type: $type
      phase: $phase
    ) {
      date
    }
  }
`;
