import gql from 'graphql-tag';

export default gql`
  mutation removeWishlist(
    $recordKey: String!
  ) {
    removeWishlist(
      tableName: "campaign"
      recordKey: $recordKey
    ) {
      id
    }
  }
`;
