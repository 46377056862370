import _slicedToArray from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import { useMobile } from './hooks';
export default function sendEvent() {
  for (var _len = arguments.length, parameters = new Array(_len), _key = 0; _key < _len; _key++) {
    parameters[_key] = arguments[_key];
  }

  if (!parameters[0]) return;
  if (window && window._paq) window._paq.push(['trackEvent'].concat(parameters));
}
export function usePlatform() {
  var _useMobile = useMobile(),
      _useMobile2 = _slicedToArray(_useMobile, 1),
      isMobile = _useMobile2[0];

  return isMobile ? 'mobile' : 'desktop';
}
export function trackAction(platform, page, action) {
  sendEvent("".concat(page, "-").concat(platform), "".concat(page, "-").concat(platform, "-").concat(action), page);
}
export function sendDelayedEvent() {
  for (var _len2 = arguments.length, parameters = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
    parameters[_key2] = arguments[_key2];
  }

  setTimeout(function () {
    sendEvent.apply(void 0, parameters);
  }, 5000);
}