import gql from 'graphql-tag';
import ContentFields from '../fragments/content';

export default gql`
  query {
    me {
      bio
      edd
      email
      phase
      phone
      status
      mobile
      website
      twitter
      premium
      vistaUrl
      followed
      username
      postcode
      facebook
      weekMail
      eddStatus
      instagram
      following
      accountType
      displayName
      emailPartner
      phaseNumeric
      profilePicture
      hasCourseAccess: activeSubscription(type: "course:borstvoedingscursus") {
        xuid
        startAt  
      }
      boughtCourses: hasCourseAccess
      pregnancyEndDate
      emailPreferencesUrl

      provider {
        city
        phone
        email
        logoUrl
        displayName
        accountName
        addressline1

        chatFeature: feature(code: chat)
        videoFeature: feature(code: video)
        filesFeature: feature(code: files)
        dossierFeature: feature(code: dossier)
        appointmentsFeature: feature(code: appointments)

        aboutContent {
          uri
        }

        primaryChannel {
          uri
          imageUrl
        }
      }

      channelSubscriptions {
        channel {
          id
          uri
          imageUrl
          displayName

          recent {
            ...ContentFields
          }
        }
      }

      wishlistEntries(tableName: "campaign") {
        campaign {
          xuid
          name
          imageUrl

          partner {
            id
            website
            imageUrl
            displayName
          }

          categories {
            category {
              name
            }
          }
        }
      }

      notifications(status: UNREAD) {
        xuid
        title
        message
        createdAt
        readAt
        link
        type {
          name
        }
      }

      segmentUsers {
        id
        segment {
          identifier
        }
      }
    }
  }
  ${ContentFields}
`;
