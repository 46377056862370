import gql from 'graphql-tag';

export default gql`
  fragment ContentFields on Content {
    id
    uri
    title
    isTag
    intro
    postedAt
    metadata
    imageUrl
    videoUrl

    author {
      link
      imageUrl
      description
      displayName
    }

    parent {
      uri
      title
    }

    tags {
      id
      uri
      title
    }

    channel {
      id
      imageUrl
      displayName
      contentCount
      numberOfFollowers
    }
  }
`;
