import { setItem } from './localStorage';
export function isPregnant(user) {
  return user.status === 'PREGNANT';
}
export function isDelivered(user) {
  return user.status === 'DELIVERED';
}
export function isPartner(user) {
  return user.accountType === 'PARTNER';
}
export function isEnded(user) {
  return user.status === 'ENDED';
}
export function isOther(user) {
  return user.accountType === 'OTHER';
}
export function hasProviderFilesAccess(user) {
  return hasAutoEddStatus(user) || isPregnant(user) && user.edd || isDelivered(user) || isEnded(user);
}
export function hasAutoEddStatus(user) {
  return user.eddStatus === 'AUTO' && !user.edd;
}
export function isCommunityGroupMember(_ref, user) {
  var members = _ref.members,
      accountType = _ref.accountType;
  return members.some(function (member) {
    return member.username === user.username;
  }) && (isPartner(user) ? accountType === 'PARTNER' : accountType !== 'PARTNER');
}
export function defaultCommunityGroup(user) {
  var groups = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var userGroups = groups.filter(function (group) {
    return group.members.find(function (member) {
      return member.username === user.username;
    });
  });
  var groupWithUserPhase = userGroups.find(function (_ref2) {
    var journeyPhases = _ref2.journeyPhases;
    return journeyPhases.find(function (_ref3) {
      var journeyPhase = _ref3.journeyPhase;
      return journeyPhase.name === user.phase;
    });
  });
  if (groupWithUserPhase) return groupWithUserPhase;
  var groupWithRecentActivity = userGroups.reduce(function (targetGroup, group) {
    if (!targetGroup) return group;
    var targetGroupDate = !!targetGroup.topics.length && new Date(Math.max.apply(null, targetGroup.topics.map(function (_ref4) {
      var createdAt = _ref4.createdAt;
      return new Date(createdAt);
    })));
    var groupDate = !!group.topics.length && new Date(Math.max.apply(null, group.topics.map(function (_ref5) {
      var createdAt = _ref5.createdAt;
      return new Date(createdAt);
    })));
    if (targetGroupDate > groupDate) return targetGroup;else return group;
  }, null);
  return groupWithRecentActivity;
}
export function communityGroupRecentActivityDate(group) {
  var topicsRecentActivityDates = group.topics.map(function (topic) {
    var topicMostRecentCommentDate = topic.events.length ? new Date(Math.max.apply(null, topic.events.map(function (_ref6) {
      var createdAt = _ref6.createdAt,
          updatedAt = _ref6.updatedAt;
      return new Date(updatedAt || createdAt);
    }))) : new Date(topic.createdAt);
    return new Date(topicMostRecentCommentDate);
  });
  return !!topicsRecentActivityDates.length && new Date(Math.max.apply(null, topicsRecentActivityDates));
}
export function defaultCoursesPage(user) {
  if (isDelivered(user)) return '/zwangerschapscursus/ouder-en-kind';
  if (user.hasCourseAccess) return '/zwangerschapscursus/overzicht';
  return '/zwangerschapscursus/welkom';
}
export function openCommunityPage(user, group, config) {
  var url = "/community/onboarding?groupXuid=".concat(group.xuid);
  setItem('originalUrl', url);
  location.href = !user.username ? config.LOGIN_URL : isCommunityGroupMember(group, user) ? "/community/".concat(group.uri) : url;
}
export function getCommunityMemberCount(groups) {
  return (groups || []).reduce(function (set, group) {
    group.members.forEach(function (member) {
      return set.add(member.username);
    });
    return set;
  }, new Set()).size;
}
export function canEditContent(user) {
  // List of users specified in https://team.linkorb.com/cards/8526
  return ['lindseyburchell', 'carel', 'maarjescheffer', 'roel', 'raizorlol'].includes(user.username);
}
export function getActiveSubscriptions() {
  var userSubscriptions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  return userSubscriptions.filter(function (_ref7) {
    var endAt = _ref7.endAt;
    return new Date() < new Date(endAt);
  });
}