import gql from 'graphql-tag';

export default gql`
  query {
    me {
      formAnswers {
        xuid
        status
        recipient
        createdBy
        createdAt
        updatedAt

        form {
          xuid
          name
        }
      }
    }
  }
`;
