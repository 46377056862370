import gql from 'graphql-tag';

export default gql`
  mutation subscribeChannel(
    $channelUri: String!
  ) {
    subscribeChannel(
      channelUri: $channelUri
    ) {
      id
    }
  }
`;
