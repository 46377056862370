import { useState, useEffect } from 'react';
export function useMobile() {
  var _useState = useState(false),
      isMobile = _useState[0],
      setIsMobile = _useState[1];

  useEffect(function () {
    if (document.body.offsetWidth <= 1200) setIsMobile(true);
  }, []);
  return [isMobile, setIsMobile];
}