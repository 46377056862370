import { useMutation } from '@apollo/client';

import addUserEventMutation from '../../requests/mutations/addUserEvent';

export function useAddUserEventMutation(fn) {
  const [addUserEvent] = useMutation(addUserEventMutation, {
    onCompleted: () => fn && fn()
  });

  return addUserEvent;
}
