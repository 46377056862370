import gql from 'graphql-tag';

export default gql`
  mutation unsubscribeChannel(
    $channelUri: String!
  ) {
    unsubscribeChannel(
      channelUri: $channelUri
    ) {
      id
    }
  }
`;
