import gql from 'graphql-tag';

export default gql`
  query {
    me {
      subscriptions {
        xuid
        type
        startAt
        endAt
        getInvoicePdfUrl
      }
    }
  }
`;
