import { useMutation } from '@apollo/client';

import markNotificationReadMutation from
  '../../requests/mutations/markNotificationRead';
import markNotificationUnreadMutation from
  '../../requests/mutations/markNotificationUnread';

export function useMarkNotificationReadMutation(fn) {
  const [markNotificationRead] = useMutation(markNotificationReadMutation, {
    onCompleted: () => fn && fn()
  });

  return markNotificationRead;
}

export function useMarkNotificationUnreadMutation(fn) {
  const [markNotificationUnread] = useMutation(markNotificationUnreadMutation, {
    onCompleted: () => fn && fn()
  });

  return markNotificationUnread;
}
