import _slicedToArray from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
export function validate() {
  for (var _len = arguments.length, fields = new Array(_len), _key = 0; _key < _len; _key++) {
    fields[_key] = arguments[_key];
  }

  var form = fields[0].parentNode.name || fields[0].parentNode.parentNode.name;
  return fields.reduce(function (isValidated, field) {
    if (!field.value.trim()) {
      field.classList.add("".concat(form, "_value_required"));
      isValidated = false;
    }

    return isValidated;
  }, true);
}
export function resetStyles(elem) {
  var form = elem.parentNode.name || elem.parentNode.parentNode.name;
  elem.classList.remove("".concat(form, "_value_required"));
}
export function submitSearchForm(e, box) {
  e.preventDefault();
  var data = new FormData(e.target);
  var query = data.get('search').trim();
  if (!query) return;
  location.href = "/search?query=".concat(query, "&box=").concat(box);
}
export function validateDate(input) {
  if (!input.value || input.value.match(/^(\d|-)+$/)) input.dataset.validatedValue = input.value;else input.value = input.dataset.validatedValue;
}
export function sliceLength(field, length) {
  if (field.value.length > length) field.value = field.value.slice(0, length);
}
export function setCustomValidity(elem, message) {
  if (!elem) return;
  elem.addEventListener('invalid', function (e) {
    e.target.setCustomValidity('');
    if (!e.target.validity.valid) e.target.setCustomValidity(message);
  });
  elem.addEventListener('input', function (e) {
    return e.target.setCustomValidity('');
  });
}
export function resetValidity(elem, violation) {
  if (!elem) return;
  elem.addEventListener('invalid', function (e) {
    if (e.target.validity[violation]) e.target.setCustomValidity('');
  });
} // https://team.linkorb.com/cards/5974
// https://team.linkorb.com/cards/6090
// https://team.linkorb.com/cards/7855
// https://team.linkorb.com/cards/8015

export function prefillSurveyForm(formXuid, survey, user) {
  if (!['puYs761oT-2RUtW62exsjQ', 'KcX8pGPFQ-aRo5QecZ2maQ', 'C9DatBYGQ0WC8_PQNGwn7w', 'wZitKGHgTkO4A5BjO1SQCw'].includes(formXuid) || !(user !== null && user !== void 0 && user.username)) return;

  var _user$displayName$spl = user.displayName.split(' '),
      _user$displayName$spl2 = _slicedToArray(_user$displayName$spl, 2),
      name = _user$displayName$spl2[0],
      surname = _user$displayName$spl2[1];

  survey.setValue('Voornaam', name);
  survey.setValue('email', user.email);
  survey.setValue('Achternaam', surname);
  survey.setValue('Name', user.displayName);
  survey.setValue('Tel-nummer', user.phone);
  survey.setValue('E-mailadres', user.email);
} // https://team.linkorb.com/cards/5974
// https://team.linkorb.com/cards/6090

export function openEmailClientWithFormData(formXuid, formData) {
  var body = "Hi zwangerenportaal.nl medewerker,\n\nZou je mij verder kunnen helpen met onderstaande vraag?\n\n".concat(Object.entries(formData).reduce(function (string, item) {
    return string += "\u2022 ".concat(item[0], ": ").concat(item[1], "\n");
  }, ''), "\nGroet, ").concat(formData.Voornaam);
  if (formXuid === 'puYs761oT-2RUtW62exsjQ' && formData.antwoord_geholpen === false || formXuid === 'KcX8pGPFQ-aRo5QecZ2maQ') location.href = "mailto:info@zwangerenportaal.nl?subject=".concat(formData.Vraag, "&body=").concat(encodeURIComponent(body));
}
export function isEmail(val) {
  return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(val);
}
export function isPostCode(val) {
  return /^\d{4}-[a-zA-Z]{2}$/.test(val);
}