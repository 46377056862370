import gql from 'graphql-tag';
import ContentFields from '../fragments/content';

export default gql`
  query channels(
    $limit: Int
    $offset: Int
    $channelLimit: Int
    $channelOffset: Int
  ) {
    channels(
      limit: $channelLimit
      offset: $channelOffset
    ) {
      id
      uri
      address
      imageUrl
      websiteUrl
      twitterUrl
      description
      displayName
      facebookUrl
      instagramUrl
      contentCount
      numberOfFollowers

      tags {
        title
      }

      organization {
        accountName
      }

      recentContent(
        limit: $limit
        offset: $offset
      ) {
        ...ContentFields
      }

      popularContent(
        limit: $limit
        offset: $offset
      ) {
        ...ContentFields
      }

      recentVideoContent(
        limit: $limit
        offset: $offset
      ) {
        ...ContentFields
      }
    }
  }
  ${ContentFields}
`;
