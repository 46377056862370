import { useMutation } from '@apollo/client';

import setUserPhotoMutation from '../../requests/mutations/setUserPhoto';
import changeUserDataMutation from '../../requests/mutations/changeUserData';

export function useChangeUserDataMutation(fn) {
  const [changeUserData] = useMutation(changeUserDataMutation, {
    onCompleted: () => fn && fn()
  });

  return changeUserData;
}

export function useSetUserPhotoMutation(fn) {
  const [setUserPhoto] = useMutation(setUserPhotoMutation, {
    onCompleted: () => fn && fn()
  });

  return setUserPhoto;
}
