import gql from 'graphql-tag';

export default gql`
  mutation addUserEvent(
    $type: String!
    $data: String!
  ) {
    addUserEvent(
      type: $type
      data: $data
    ) {
      id
    }
  }
`;
