import { useQuery } from '@apollo/client';
import userForms from '../../requests/queries/userForms';
import userFormAnswers from '../../requests/queries/userFormAnswers';

export function useUserTasks() {
  const { data: userFormAnswersData = {} } =
    useQuery(userFormAnswers);

  const {
    data: userFormsData = {}
  } = useQuery(userForms);

  return [
    ...(userFormAnswersData?.me?.formAnswers?.filter(
      (formAnswer) => formAnswer.status === 'PENDING'
    ) || []),
    ...(userFormsData?.me?.forms?.filter((form) => form.type === 'PROVIDER') ||
      [])
  ] || [];
}
