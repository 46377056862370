export default function Preloader() {
  return (
    <div style={{
      height: '100vh',
      marginTop: '50vh',
      textAlign: 'center'
    }}>
      <svg className="rotate" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13 25C19.6274 25 25 19.6274 25 13C25 6.37258 19.6274 1 13 1C6.37258 1 1 6.37258 1 13C1 19.6274 6.37258 25 13 25Z" stroke="#6A749D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M10.6351 17.9006H10.9479H18.1429C18.6612 17.9006 19.3942 17.56 19.3942 17.14V11.0555C19.3942 10.6354 18.974 10.2949 18.4557 10.2949H7.19397C6.67566 10.2949 6.25549 10.6354 6.25549 11.0555V17.14C6.25549 17.56 6.67566 17.9006 7.19397 17.9006H10.6351Z" fill="#6A749D"/>
        <path d="M10.9479 17.9006H7.19397C6.67566 17.9006 6.25549 17.56 6.25549 17.14V11.0555C6.25549 10.6354 6.67566 10.2949 7.19397 10.2949H18.4557C18.974 10.2949 19.3942 10.6354 19.3942 11.0555V17.14C19.3942 17.56 18.6612 17.9006 18.1429 17.9006H10.6351" stroke="#6A749D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12.8249 14.6896C13.5505 14.6896 14.1387 14.0464 14.1387 13.253C14.1387 12.4596 13.5505 11.8164 12.8249 11.8164C12.0992 11.8164 11.511 12.4596 11.511 13.253C11.511 14.0464 12.0992 14.6896 12.8249 14.6896Z" fill="white"/>
        <path d="M12.7625 16.1078C13.4881 16.1078 13.3756 15.3987 13.3756 14.6053C13.3756 13.8118 13.4881 13.2346 12.7625 13.2346C12.0369 13.2346 12.2369 13.2017 12.2369 13.9951C12.2369 14.7886 12.0369 16.1078 12.7625 16.1078Z" fill="white"/>
        <path d="M18.7811 17.9005C20.8612 17.9005 22.5475 16.2357 22.5475 14.1822C22.5475 12.1286 20.8612 10.4639 18.7811 10.4639C16.7009 10.4639 15.0146 12.1286 15.0146 14.1822C15.0146 16.2357 16.7009 17.9005 18.7811 17.9005Z" fill="white"/>
        <path d="M18.7811 17.9005C20.8612 17.9005 22.5475 16.2357 22.5475 14.1822C22.5475 12.1286 20.8612 10.4639 18.7811 10.4639C16.7009 10.4639 15.0146 12.1286 15.0146 14.1822C15.0146 16.2357 16.7009 17.9005 18.7811 17.9005Z" fill="white"/>
        <path d="M18.781 16.7179C20.1839 16.7179 21.3211 15.5828 21.3211 14.1827C21.3211 12.7825 20.1839 11.6475 18.781 11.6475C17.3781 11.6475 16.2408 12.7825 16.2408 14.1827C16.2408 15.5828 17.3781 16.7179 18.781 16.7179Z" stroke="#6A749D" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M19.7925 13.5068L18.6642 14.6932C18.6134 14.7464 18.5359 14.7797 18.4517 14.7844C18.3675 14.7892 18.2848 14.765 18.2249 14.718L17.9336 14.4883L17.788 14.3734L17.6423 14.2585" stroke="#6A749D" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M9.40881 10.1263C9.40881 8.35277 10.9382 6.91504 12.8249 6.91504C14.7115 6.91504 16.2409 8.35277 16.2409 10.1263" stroke="#6A749D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </div>
  );
}
