export function getItem(key) {
  try {
    if (localStorage)
      return localStorage.getItem(key);
  } catch (e) {}
}

export function setItem(key, value) {
  try {
    if (localStorage)
      localStorage.setItem(key, value);
  } catch (e) {}
}

export function removeItem(key) {
  try {
    if (localStorage)
      localStorage.removeItem(key);
  } catch (e) {}
}
