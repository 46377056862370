import { useMutation } from '@apollo/client';

import addWishlistMutation from
  '../../requests/mutations/addWishlist';
import removeWishlistMutation from
  '../../requests/mutations/removeWishlist';

export function useAddWishlistMutationMutation(refetch) {
  const [addWishlist] = useMutation(addWishlistMutation, {
    onCompleted: () => refetch()
  });

  return addWishlist;
}

export function useRemoveWishlistMutationMutation(refetch) {
  const [removeWishlist] = useMutation(removeWishlistMutation, {
    onCompleted: () => refetch()
  });

  return removeWishlist;
}
