import gql from 'graphql-tag';

export default gql`
  mutation markNotificationUnread(
    $xuid: String!
  ) {
    markNotificationUnread(
      xuid: $xuid
    ) {
      xuid
    }
  }
`;
