import gql from 'graphql-tag';

export default gql`
  mutation changeUserData(
    $bio: String
    $edd: String
    $payCity: String
    $website: String
    $twitter: String
    $postcode: String
    $facebook: String
    $provider: String
    $instagram: String
    $payAddress: String
    $insurerXuid: String
    $payBirthdate: String
    $emailPartner: String
    $payPostalcode: String
    $providerStatus: String
    $pregnancyEndDate: String
    $eddStatus: EddStatusEnum
    $premium: UserDataPremium
    $insurerPolicyXuid: String
    $weekMail: UserDataWeekMail
    $accountType: AccountTypeEnum
    $visibility: UserDataVisibility
    $status: UserPregnancyStatusEnum
    $partnerInviteStatus: PartnerInviteStatusEnum
  ) {
    changeUserData(
      bio: $bio
      edd: $edd
      status: $status
      payCity: $payCity
      website: $website
      twitter: $twitter
      premium: $premium
      postcode: $postcode
      provider: $provider
      facebook: $facebook
      weekMail: $weekMail
      eddStatus: $eddStatus
      instagram: $instagram
      payAddress: $payAddress
      visibility: $visibility
      accountType: $accountType
      insurerXuid: $insurerXuid
      payBirthdate: $payBirthdate
      emailPartner: $emailPartner
      payPostalcode: $payPostalcode
      providerStatus: $providerStatus
      pregnancyEndDate: $pregnancyEndDate
      insurerPolicyXuid: $insurerPolicyXuid
      partnerInviteStatus: $partnerInviteStatus
    ) {
      username
    }
  }
`;
