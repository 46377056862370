import gql from 'graphql-tag';

export default gql`
  mutation setUserPhoto(
    $file: String!
  ) {
    setUserPhoto(
      file: $file
    ) {
      username
    }
  }
`;
