import { useMutation } from '@apollo/client';

import subscribeChannelMutation from
  '../../requests/mutations/subscribeChannel';
import unsubscribeChannelMutation from
  '../../requests/mutations/unsubscribeChannel';

export function useSubscribeChannelMutation(refetch) {
  const [subscribeChannel] = useMutation(subscribeChannelMutation, {
    onCompleted: () => refetch()
  });

  return subscribeChannel;
}

export function useUnsubscribeChannelMutation(refetch) {
  const [unsubscribeChannel] = useMutation(unsubscribeChannelMutation, {
    onCompleted: () => refetch()
  });

  return unsubscribeChannel;
}
