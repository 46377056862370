import React from 'react';
import initApollo from './initApollo';
import { getDataFromTree } from '@apollo/client/react/ssr';

export default App => class Apollo extends React.Component {
  static async getInitialProps(ctx) {
    const withHeaders = ctx.ctx.req && ctx.ctx.req.headers;

    const jwt = ctx.ctx.query.jwt ||
      parseCookies(withHeaders && ctx.ctx.req.headers.cookie);

    const apollo = initApollo({ jwt });

    const { Component, router } = ctx;

    let appProps = {};

    if (App.getInitialProps) appProps = await App.getInitialProps(ctx);

    if (!process.browser) {
      try {
        await getDataFromTree(
          <App
            {...appProps}
            Component={Component}
            router={router}
            apolloClient={apollo}
          />
        );
      } catch (e) {}
    }

    const apolloState = apollo.cache.extract();

    return { ...appProps, apolloState, jwt };
  }

  constructor(props) {
    super(props);

    props.apolloState.jwt = props.jwt;

    this.apolloClient = initApollo(props.apolloState);
  }

  render() {
    return <App {...this.props} apolloClient={this.apolloClient} />;
  }
};

function parseCookies(cookies) {
  if (!cookies) return;

  const cookie = cookies.split('; ').find(item =>
    item.split('=')[0] === 'jwt');

  return cookie && cookie.split('=')[1];
}
