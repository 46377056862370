export function getItem(key) {
  try {
    if (sessionStorage) return sessionStorage.getItem(key);
  } catch (e) {}
}
export function setItem(key, value) {
  try {
    if (sessionStorage) sessionStorage.setItem(key, value);
  } catch (e) {}
}
export function removeItem(key) {
  try {
    if (sessionStorage) sessionStorage.removeItem(key);
  } catch (e) {}
}