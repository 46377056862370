import { defaultCoursesPage } from 'plaza-react-components/src/helpers/user';

export default user => {
  const isPartner = user.accountType === 'PARTNER';

  return {
    menu: [{
      label: 'Magazine',
      link: '/',
      tracking: {
        dashboardDesktop: ['dashboard-desktop', 'dashboard-desktop-menumagazine'],
        partnerDashboardDesktop: ['dashboard-partner-desktop', 'dashboard-partner-desktop-menumagazine']
      }
    }, {
      label: 'Zwanger worden',
      link: '/zwanger-worden',
      desktopLink: true,
      tracking: {
        homeDesktop: ['menu-home-desktop', 'menu-home-desktop-zwanger-worden'],
        dashboardDesktop: ['dashboard-desktop', 'dashboard-desktop-menuzwangerworden'],
        partnerDashboardDesktop: ['dashboard-partner-desktop', 'dashboard-partner-desktop-menuzwangerworden']
      },
      submenu: [{
        label: 'Kinderwens',
        link: '/zwanger-worden/kinderwens',
        eventTrackingCodes: ['zwanger-worden', 'zwanger-worden-kinderwens']
      }, {
        label: 'Vruchtbare dagen',
        link: '/zwanger-worden/vruchtbare-dagen-berekenen',
        eventTrackingCodes: ['zwanger-worden', 'zwanger-worden-vruchtbare-dagen']
      }, {
        label: 'Ben ik zwanger?',
        link: '/zwanger-worden/ben-ik-zwanger',
        eventTrackingCodes: ['zwanger-worden', 'zwanger-worden-ben-ik-zwanger']
      }, {
        label: 'Wat als het niet lukt?',
        link: '/zwanger-worden/wat-als-het-niet-lukt',
        eventTrackingCodes:
          ['zwanger-worden', 'zwanger-worden-wat-als-het-niet-lukt']
      }]
    }, {
      label: 'Zwanger',
      link: '/zwanger',
      tracking: {
        homeDesktop: ['menu-home-desktop', 'menu-home-desktop-zwanger'],
        homeMobile: ['menu-home-mobile', 'menu-home-mobile-zwanger'],
        dashboardDesktop: ['dashboard-desktop', 'dashboard-desktop-menuzwanger'],
        partnerDashboardDesktop: ['dashboard-partner-desktop', 'dashboard-partner-desktop-menuzwanger']
      },
      submenu: [{
        label: 'Zwangerschapskalender',
        link: `${isPartner ? '/zwanger/partnerkalender' : '/zwanger/zwangerschapskalender'}`,
        alias: '/week-',
        eventTrackingCodes: ['zwanger', 'zwanger-zwangerschapskalender']
      }, {
        label: 'Uitgerekende datum',
        link: '/zwanger/uitgerekende-datum',
        eventTrackingCodes: ['zwanger', 'zwanger-uitgerekende-datum']
      }, {
        label: 'De babyuitzetlijst',
        link: '/zwanger/babyuitzetlijst',
        eventTrackingCodes: ['zwanger', 'zwanger-de-babyuitzetlijst']
      }, {
        label: 'Cursusportaal',
        link: defaultCoursesPage(user),
        eventTrackingCodes: ['zwanger', 'zwanger-cursusportaal']
      }, {
        label: 'Gezondheid',
        link: '/zwanger/gezondheid',
        eventTrackingCodes: ['zwanger', 'zwanger-gezondheid']
      }, {
        label: 'Eten',
        link: '/zwanger/eten-tijdens-de-zwangerschap',
        eventTrackingCodes: ['zwanger', 'zwanger-eten']
      }, {
        label: 'Zwangerschapschecklist',
        link: '/zwanger/checklist',
        eventTrackingCodes: ['zwanger', 'zwanger-zwangerschapschecklist']
      }, {
        label: 'Onderzoeken en controles',
        link: '/zwanger/onderzoeken-en-controles',
        eventTrackingCodes: ['zwanger', 'zwanger-onderzoeken-en-controles']
      }, {
        label: 'Financiën',
        link: '/zwanger/financien',
        eventTrackingCodes: ['zwanger', 'financien']
      }, {
        label: 'Kwaaltjes and klachten',
        link: '/zwanger/kwaaltjes-en-klachten',
        eventTrackingCodes: ['zwanger', 'zwanger-kwaaltjes-en-klachten-question']
      }]
    }, {
      label: 'Bevalling',
      link: '/bevalling',
      tracking: {
        homeDesktop: ['menu-home-desktop', 'menu-home-desktop-bevalling'],
        dashboardDesktop: ['dashboard-desktop', 'dashboard-desktop-bevalling'],
        partnerDashboardDesktop: ['dashboard-partner-desktop', 'dashboard-partner-desktop-bevalling']
      },
      submenu: [{
        label: 'Voorbereidingen',
        link: '/bevalling/voorbereidingen',
        eventTrackingCodes: ['bevalling', 'bevalling-voorbereidingen']
      }, {
        label: 'Pijnbestrijding',
        link: '/bevalling/pijnbestrijding',
        eventTrackingCodes: ['bevalling', 'bevalling-pijnbestrijding']
      }, {
        label: 'Alles over weeën',
        link: '/bevalling/alles-over-ween',
        eventTrackingCodes: ['bevalling', 'bevalling-alles-over-weeen']
      }, {
        label: 'Herstel',
        link: '/herstel',
      }, {
        label: 'Bevallingsverhalen',
        link: '/bevalling/bevallingsverhalen',
        eventTrackingCodes: ['bevalling', 'bevalling-bevallingsverhalen']
      }, {
        label: 'Geboortekaartjes',
        link: '/bevalling/geboortekaartjes-de-mooiste-opties-op-een-rijtje',
        eventTrackingCodes: ['bevalling', 'bevalling-geboortekaartjes']
      }, {
        label: 'Complicaties',
        link: '/bevalling/complicaties-tijdens-de-bevalling',
        eventTrackingCodes: ['bevalling', 'bevalling-complicaties']
      }],
      desktopLink: true
    }, {
      label: 'Baby en kind',
      link: '/baby',
      tracking: {
        homeDesktop: ['menu-home-desktop', 'menu-home-desktop-baby'],
        homeMobile: ['menu-home-mobile', 'menu-home-mobile-baby'],
        dashboardDesktop: ['dashboard-desktop', 'dashboard-desktop-baby'],
        partnerDashboardDesktop: ['dashboard-partner-desktop', 'dashboard-partner-desktop-baby']
      },
      submenu: [{
        label: 'Groeikalender',
        link: '/baby/groeikalender',
        alias: '/baby-week-',
        eventTrackingCodes: ['baby', 'baby-babykalender']
      }, {
        label: 'De kraamtijd',
        link: '/baby/de-kraamtijd',
        eventTrackingCodes: ['baby', 'baby-de-kraamtijd']
      }, {
        label: 'Borstvoeding',
        link: '/baby/borstvoeding-voor-je-baby',
      }, {
        label: 'Voeding',
        link: '/baby/voeding',
        eventTrackingCodes: ['baby', 'baby-voeding']
      }, {
        label: 'Verzorging',
        link: '/baby/verzorging',
        eventTrackingCodes: ['baby', 'baby-verzorging']
      }, {
        label: 'Slapen',
        link: '/baby/slaap',
      }, {
        label: 'Babyspullen',
        link: '/baby/babyspullen',
        eventTrackingCodes: ['baby', 'baby--babyspullen']
      }, {
        label: 'Kwaaltjes en ziektes',
        link: '/baby/kwaaltjes-en-ziektes',
        eventTrackingCodes: ['baby', 'baby-kwaaltjes-en-ziektes']
      }, {
        label: 'Praktische zaken',
        link: '/praktische-zaken',
      }, {
        label: 'Opvoeden',
        link: '/baby/opvoeden',
        eventTrackingCodes: ['baby', 'baby-opvoeden']
      }, {
        label: 'School',
        link: '/school',
        eventTrackingCodes: ['baby', 'baby-school']
      }]
    }, {
      label: 'Cursusportaal',
      link: defaultCoursesPage(user),
      tracking: {
        homeDesktop: ['menu-home-desktop', 'menu-home-desktop-cursusportaal'],
        homeMobile: ['menu-home-mobile', 'menu-home-mobile-Cursusportaal']
      }
    }, {
      label: 'Zorgdashboard',
      link: '/dashboard',
      tracking: {
        homeDesktop: ['menu-home-desktop', 'menu-home-desktop-mijn-dashboard'],
        homeMobile: ['menu-home-mobile', 'menu-home-mobile-mijn-dashboard']
      }
    }],

    superMenu: ([!isPartner && {
      isMobile: true,
      label: 'Zorgdashboard',
      link: '/dashboard',
      menu: [{
        label: 'Mijn dossier',
        link: '/files',
        tracking: {
          homeMobile: ['menu-home-mobile-hamburger', 'menu-home-mobile-hamburger-mijn-dossier'],
          dashboardMobile: ['menu-dashboard-mobile-hamburger', 'menu-dashboard-mobile-hamburger-mijn-dossier']
        }
      }, {
        label: 'Mijn afspraken',
        link: '/appointments',
        tracking: {
          homeMobile: ['menu-home-mobile-hamburger', 'menu-home-mobile-hamburger-mijn-afsprekenn'],
          dashboardMobile: ['menu-dashboard-mobile-hamburger', 'menu-dashboard-mobile-hamburger-mijn-afsprekenn']
        }
      },{
        label: 'Mijn magazine',
        link: '/?tab=news',
        tracking: {
          homeMobile: ['menu-home-mobile-hamburger', 'menu-home-mobile-hamburger-mijn-magazine-top'],
          dashboardMobile: ['menu-dashboard-mobile-hamburger', 'menu-dashboard-mobile-hamburger-mijn-magazine-top']
        }
      }, {
        label: 'Videoconsult',
        link: '/video-call',
        tracking: {
          homeMobile: ['menu-home-mobile-hamburger', 'menu-home-mobile-hamburger-videoconsult'],
          dashboardMobile: ['menu-dashboard-mobile-hamburger', 'menu-dashboard-mobile-hamburger-videoconsult']
        }
      }, {
        label: '1-op-1 chat',
        link: '/rooms',
        tracking: {
          homeMobile: ['menu-home-mobile-hamburger', 'menu-home-mobile-hamburger-chat'],
          dashboardMobile: ['menu-dashboard-mobile-hamburger', 'menu-dashboard-mobile-hamburger-chat']
        }
      }, {
        label: 'Gedeelde documenten',
        link: '/shared-folders',
        tracking: {
          homeMobile: ['menu-home-mobile-hamburger', 'menu-home-mobile-hamburger-documenten'],
          dashboardMobile: ['menu-dashboard-mobile-hamburger', 'menu-dashboard-mobile-hamburger-documenten']
        }
      }]
    }, {
      label: 'Zwanger worden',
      link: '/zwanger-worden',
      tracking: {
        homeDesktop: ['menu-home-desktop-hamburger', 'menu-home-desktop-hamburger-zwanger-worden'],
        homeMobile: ['menu-home-mobile-hamburger', 'menu-home-mobile-hamburger-zwanger-worden'],
        dashboardDesktop: ['menu-dashboard-desktop-hamburger', 'menu-dashboard-desktop-hamburger-zwanger-worden'],
        dashboardMobile: ['menu-dashboard-mobile-hamburger', 'menu-dashboard-mobile-hamburger-zwanger-worden']
      },
      menu: [{
        label: 'Kinderwens',
        link: '/zwanger-worden/kinderwens',
        tracking: {
          homeDesktop: ['menu-home-desktop-hamburger', 'menu-home-desktop-hamburger-kinderwens'],
          homeMobile: ['menu-home-mobile-hamburger', 'menu-home-mobile-hamburger-kinderwens'],
          dashboardDesktop: ['menu-dashboard-desktop-hamburger', 'menu-dashboard-desktop-hamburger-kinderwens'],
          dashboardMobile: ['menu-dashboard-mobile-hamburger', 'menu-dashboard-mobile-hamburger-kinderwens']
        }
      }, {
        label: 'Vruchtbare dagen',
        link: '/zwanger-worden/vruchtbare-dagen-berekenen',
        tracking: {
          homeDesktop: ['menu-home-desktop-hamburger', 'menu-home-desktop-hamburger-vruchtbare-dagen'],
          homeMobile: ['menu-home-mobile-hamburger', 'menu-home-mobile-hamburger-vruchtbare-dagen'],
          dashboardDesktop: ['menu-dashboard-desktop-hamburger', 'menu-dashboard-desktop-hamburger-vruchtbare-dagen'],
          dashboardMobile: ['menu-dashboard-mobile-hamburger', 'menu-dashboard-mobile-hamburger-vruchtbare-dagen']
        }
      }, {
        label: 'Ben ik zwanger?',
        link: '/zwanger-worden/ben-ik-zwanger',
        tracking: {
          homeDesktop: ['menu-home-desktop-hamburger', 'menu-home-desktop-hamburger-ben-ik-zwanger'],
          homeMobile: ['menu-home-mobile-hamburger', 'menu-home-mobile-hamburger-ben-ik-zwanger'],
          dashboardDesktop: ['menu-dashboard-desktop-hamburger', 'menu-dashboard-desktop-hamburger-ben-ik-zwanger'],
          dashboardMobile: ['menu-dashboard-mobile-hamburger', 'menu-dashboard-mobile-hamburger-ben-ik-zwanger']
        }
      }, {
        label: 'Wat als het niet lukt?',
        link: '/zwanger-worden/wat-als-het-niet-lukt',
        tracking: {
          homeDesktop: ['menu-home-desktop-hamburger', 'menu-home-desktop-hamburger-wat-als-het-niet-lukt'],
          homeMobile: ['menu-home-mobile-hamburger', 'menu-home-mobile-hamburger-wat-als-het-niet-lukt'],
          dashboardDesktop: ['menu-dashboard-desktop-hamburger', 'menu-dashboard-desktop-hamburger-wat-als-het-niet-lukt'],
          dashboardMobile: ['menu-dashboard-mobile-hamburger', 'menu-dashboard-mobile-hamburger-wat-als-het-niet-lukt']
        }
      }]
    }, {
      label: 'Zwanger',
      link: '/zwanger',
      tracking: {
        homeDesktop: ['menu-home-desktop-hamburger', 'menu-home-desktop-hamburger-zwanger'],
        homeMobile: ['menu-home-mobile-hamburger', 'menu-home-mobile-hamburger-zwanger'],
        dashboardDesktop: ['menu-dashboard-desktop-hamburger', 'menu-dashboard-desktop-hamburger-zwanger'],
        dashboardMobile: ['menu-dashboard-mobile-hamburger', 'menu-dashboard-mobile-hamburger-zwanger']
      },
      menu: [{
        label: 'Zwangerschapskalender',
        link: `${isPartner ? '/zwanger/partnerkalender' : '/zwanger/zwangerschapskalender'}`,
        tracking: {
          homeDesktop: ['menu-home-desktop-hamburger', 'menu-home-desktop-hamburger-zwangerschapskalender'],
          homeMobile: ['menu-home-mobile-hamburger', 'menu-home-mobile-hamburger-zwangerschapskalender'],
          dashboardDesktop: ['menu-dashboard-desktop-hamburger', 'menu-dashboard-desktop-hamburger-zwangerschapskalender'],
          dashboardMobile: ['menu-dashboard-mobile-hamburger', 'menu-dashboard-mobile-hamburger-zwangerschapskalender']
        }
      }, {
        label: 'Uitgerekende datum',
        link: '/zwanger/uitgerekende-datum',
        tracking: {
          homeDesktop: ['menu-home-desktop-hamburger', 'menu-home-desktop-hamburger-uitgerekende-datum'],
          homeMobile: ['menu-home-mobile-hamburger', 'menu-home-mobile-hamburger-uitgerekende-datum'],
          dashboardDesktop: ['menu-dashboard-desktop-hamburger', 'menu-dashboard-desktop-hamburger-uitgerekende-datum'],
          dashboardMobile: ['menu-dashboard-mobile-hamburger', 'menu-dashboard-mobile-hamburger-uitgerekende-datum']
        }
      }, {
        label: 'De babyuitzetlijst',
        link: '/zwanger/babyuitzetlijst',
        tracking: {
          homeDesktop: ['menu-home-desktop-hamburger', 'menu-home-desktop-hamburger-de-babyuitzetlijst'],
          homeMobile: ['menu-home-mobile-hamburger', 'menu-home-mobile-hamburger-de-babyuitzetlijst'],
          dashboardDesktop: ['menu-dashboard-desktop-hamburger', 'menu-dashboard-desktop-hamburger-de-babyuitzetlijst'],
          dashboardMobile: ['menu-dashboard-mobile-hamburger', 'menu-dashboard-mobile-hamburger-de-babyuitzetlijst']
        }
      }, {
        label: 'Cursusportaal',
        link: defaultCoursesPage(user),
        tracking: {
          homeDesktop: ['menu-home-desktop-hamburger', 'menu-home-desktop-hamburger-cursusportaal'],
          homeMobile: ['menu-home-mobile-hamburger', 'menu-home-mobile-hamburger-cursusportaal'],
          dashboardDesktop: ['menu-dashboard-desktop-hamburger', 'menu-dashboard-desktop-hamburger-cursusportaal'],
          dashboardMobile: ['menu-dashboard-mobile-hamburger', 'menu-dashboard-mobile-hamburger-cursusportaal']
        }
      }, {
        label: 'Gezondheid',
        link: '/zwanger/gezondheid',
        tracking: {
          homeDesktop: ['menu-home-desktop-hamburger', 'menu-home-desktop-hamburger-gezondheid'],
          homeMobile: ['menu-home-mobile-hamburger', 'menu-home-mobile-hamburger-gezondheid'],
          dashboardDesktop: ['menu-dashboard-desktop-hamburger', 'menu-dashboard-desktop-hamburger-gezondheid'],
          dashboardMobile: ['menu-dashboard-mobile-hamburger', 'menu-dashboard-mobile-hamburger-gezondheid']
        }
      }, {
        label: 'Eten',
        link: '/zwanger/eten-tijdens-de-zwangerschap',
        tracking: {
          homeDesktop: ['menu-home-desktop-hamburger', 'menu-home-desktop-hamburger-eten'],
          homeMobile: ['menu-home-mobile-hamburger', 'menu-home-mobile-hamburger-eten'],
          dashboardDesktop: ['menu-dashboard-desktop-hamburger', 'menu-dashboard-desktop-hamburger-eten'],
          dashboardMobile: ['menu-dashboard-mobile-hamburger', 'menu-dashboard-mobile-hamburger-eten']
        }
      }, {
        label: 'Zwangerschapschecklist',
        link: '/zwanger/checklist',
        tracking: {
          homeDesktop: ['menu-home-desktop-hamburger', 'menu-home-desktop-hamburger-zwangerschapschecklist'],
          homeMobile: ['menu-home-mobile-hamburger', 'menu-home-mobile-hamburger-zwangerschapschecklist'],
          dashboardDesktop: ['menu-dashboard-desktop-hamburger', 'menu-dashboard-desktop-hamburger-zwangerschapschecklist'],
          dashboardMobile: ['menu-dashboard-mobile-hamburger', 'menu-dashboard-mobile-hamburger-zwangerschapschecklist']
        }
      }, {
        label: 'Onderzoeken en controles',
        link: '/zwanger/onderzoeken-en-controles',
        tracking: {
          homeDesktop: ['menu-home-desktop-hamburger', 'menu-home-desktop-hamburger-onderzoeken-en-controles'],
          homeMobile: ['menu-home-mobile-hamburger', 'menu-home-mobile-hamburger-onderzoeken-en-controles'],
          dashboardDesktop: ['menu-dashboard-desktop-hamburger', 'menu-dashboard-desktop-hamburger-onderzoeken-en-controles'],
          dashboardMobile: ['menu-dashboard-mobile-hamburger', 'menu-dashboard-mobile-hamburger-onderzoeken-en-controles']
        }
      }, {
        label: 'Financiën',
        link: '/zwanger/financien',
        tracking: {
          homeDesktop: ['menu-home-desktop-hamburger', 'menu-home-desktop-hamburger-financien'],
          homeMobile: ['menu-home-mobile-hamburger', 'menu-home-mobile-hamburger-financien'],
          dashboardDesktop: ['menu-dashboard-desktop-hamburger', 'menu-dashboard-desktop-hamburger-financien'],
          dashboardMobile: ['menu-dashboard-mobile-hamburger', 'menu-dashboard-mobile-hamburger-financien']
        }
      }, {
        label: 'Kwaaltjes and klachten',
        link: '/zwanger/kwaaltjes-en-klachten',
        tracking: {
          homeDesktop: ['menu-home-desktop-hamburger', 'menu-home-desktop-hamburger-kwaaltjes-en-klachten-question'],
          homeMobile: ['menu-home-mobile-hamburger', 'menu-home-mobile-hamburger-kwaaltjes-en-klachten-question'],
          dashboardDesktop: ['menu-dashboard-desktop-hamburger', 'menu-dashboard-desktop-hamburger-kwaaltjes-en-klachten-question'],
          dashboardMobile: ['menu-dashboard-mobile-hamburger', 'menu-dashboard-mobile-hamburger-kwaaltjes-en-klachten-question']
        }
      }]
    }, {
      label: 'Bevalling',
      link: '/bevalling',
      tracking: {
        homeDesktop: ['menu-home-desktop-hamburger', 'menu-home-desktop-hamburger-bevalling'],
        homeMobile: ['menu-home-mobile-hamburger', 'menu-home-mobile-hamburger-bevalling'],
        dashboardDesktop: ['menu-dashboard-desktop-hamburger', 'menu-dashboard-desktop-hamburger-bevalling'],
        dashboardMobile: ['menu-dashboard-mobile-hamburger', 'menu-dashboard-mobile-hamburger-bevalling']
      },
      menu: [{
        label: 'Voorbereidingen',
        link: '/bevalling/voorbereidingen',
        tracking: {
          homeDesktop: ['menu-home-desktop-hamburger', 'menu-home-desktop-hamburger-voorbereidingen'],
          homeMobile: ['menu-home-mobile-hamburger', 'menu-home-mobile-hamburger-voorbereidingen'],
          dashboardDesktop: ['menu-dashboard-desktop-hamburger', 'menu-dashboard-desktop-hamburger-voorbereidingen'],
          dashboardMobile: ['menu-dashboard-mobile-hamburger', 'menu-dashboard-mobile-hamburger-voorbereidingen']
        }
      }, {
        label: 'Pijnbestrijding',
        link: '/bevalling/pijnbestrijding',
        tracking: {
          homeDesktop: ['menu-home-desktop-hamburger', 'menu-home-desktop-hamburger-pijnbestrijding'],
          homeMobile: ['menu-home-mobile-hamburger', 'menu-home-mobile-hamburger-pijnbestrijding'],
          dashboardDesktop: ['menu-dashboard-desktop-hamburger', 'menu-dashboard-desktop-hamburger-pijnbestrijding'],
          dashboardMobile: ['menu-dashboard-mobile-hamburger', 'menu-dashboard-mobile-hamburger-pijnbestrijding']
        }
      }, {
        label: 'Alles over weeën',
        link: '/bevalling/alles-over-ween',
        tracking: {
          homeDesktop: ['menu-home-desktop-hamburger', 'menu-home-desktop-hamburger-alles-over-weeen'],
          homeMobile: ['menu-home-mobile-hamburger', 'menu-home-mobile-hamburger-alles-over-weeen'],
          dashboardDesktop: ['menu-dashboard-desktop-hamburger', 'menu-dashboard-desktop-hamburger-alles-over-weeen'],
          dashboardMobile: ['menu-dashboard-mobile-hamburger', 'menu-dashboard-mobile-hamburger-alles-over-weeen']
        }
      }, {
        label: 'Herstel',
        link: '/herstel',
      }, {
        label: 'Bevallingsverhalen',
        link: '/bevalling/bevallingsverhalen',
        tracking: {
          homeDesktop: ['menu-home-desktop-hamburger', 'menu-home-desktop-hamburger-bevallingsverhalen'],
          homeMobile: ['menu-home-mobile-hamburger', 'menu-home-mobile-hamburger-bevallingsverhalen'],
          dashboardDesktop: ['menu-dashboard-desktop-hamburger', 'menu-dashboard-desktop-hamburger-bevallingsverhalen'],
          dashboardMobile: ['menu-dashboard-mobile-hamburger', 'menu-dashboard-mobile-hamburger-bevallingsverhalen']
        }
      }, {
        label: 'Geboortekaartjes',
        link: '/bevalling/geboortekaartjes-de-mooiste-opties-op-een-rijtje',
        tracking: {
          homeDesktop: ['menu-home-desktop-hamburger', 'menu-home-desktop-hamburger-geboortekaartjes'],
          homeMobile: ['menu-home-mobile-hamburger', 'menu-home-mobile-hamburger-geboortekaartjes'],
          dashboardDesktop: ['menu-dashboard-desktop-hamburger', 'menu-dashboard-desktop-hamburger-geboortekaartjes'],
          dashboardMobile: ['menu-dashboard-mobile-hamburger', 'menu-dashboard-mobile-hamburger-geboortekaartjes']
        }
      }, {
        label: 'Complicaties',
        link: '/bevalling/complicaties-tijdens-de-bevalling',
        tracking: {
          homeDesktop: ['menu-home-desktop-hamburger', 'menu-home-desktop-hamburger-complicaties'],
          homeMobile: ['menu-home-mobile-hamburger', 'menu-home-mobile-hamburger-complicaties'],
          dashboardDesktop: ['menu-dashboard-desktop-hamburger', 'menu-dashboard-desktop-hamburger-complicaties'],
          dashboardMobile: ['menu-dashboard-mobile-hamburger', 'menu-dashboard-mobile-hamburger-complicaties']
        }
      }]
    }, {
      label: 'Baby en kind',
      link: '/baby',
      tracking: {
        homeDesktop: ['menu-home-desktop-hamburger', 'menu-home-desktop-hamburger-baby'],
        homeMobile: ['menu-home-mobile-hamburger', 'menu-home-mobile-hamburger-baby'],
        dashboardDesktop: ['menu-dashboard-desktop-hamburger', 'menu-dashboard-desktop-hamburger-baby'],
        dashboardMobile: ['menu-dashboard-mobile-hamburger', 'menu-dashboard-mobile-hamburger-baby']
      },
      menu: [{
        label: 'Groeikalender',
        link: '/baby/groeikalender',
        tracking: {
          homeDesktop: ['menu-home-desktop-hamburger', 'menu-home-desktop-hamburger-babykalender'],
          homeMobile: ['menu-home-mobile-hamburger', 'menu-home-mobile-hamburger-babykalender'],
          dashboardDesktop: ['menu-dashboard-desktop-hamburger', 'menu-dashboard-desktop-hamburger-babykalender'],
          dashboardMobile: ['menu-dashboard-mobile-hamburger', 'menu-dashboard-mobile-hamburger-babykalender']
        }
      }, {
        label: 'De kraamtijd',
        link: '/baby/de-kraamtijd',
        tracking: {
          homeDesktop: ['menu-home-desktop-hamburger', 'menu-home-desktop-hamburger-de-kraamtijd'],
          homeMobile: ['menu-home-mobile-hamburger', 'menu-home-mobile-hamburger-de-kraamtijd'],
          dashboardDesktop: ['menu-dashboard-desktop-hamburger', 'menu-dashboard-desktop-hamburger-de-kraamtijd'],
          dashboardMobile: ['menu-dashboard-mobile-hamburger', 'menu-dashboard-mobile-hamburger-de-kraamtijd']
        }
      }, {
        label: 'Borstvoeding',
        link: '/baby/borstvoeding-voor-je-baby',
      }, {
        label: 'Voeding',
        link: '/baby/voeding',
        tracking: {
          homeDesktop: ['menu-home-desktop-hamburger', 'menu-home-desktop-hamburger-voeding'],
          homeMobile: ['menu-home-mobile-hamburger', 'menu-home-mobile-hamburger-voeding'],
          dashboardDesktop: ['menu-dashboard-desktop-hamburger', 'menu-dashboard-desktop-hamburger-voeding'],
          dashboardMobile: ['menu-dashboard-mobile-hamburger', 'menu-dashboard-mobile-hamburger-voeding']
        }
      }, {
        label: 'Verzorging',
        link: '/baby/verzorging',
        tracking: {
          homeDesktop: ['menu-home-desktop-hamburger', 'menu-home-desktop-hamburger-verzorging'],
          homeMobile: ['menu-home-mobile-hamburger', 'menu-home-mobile-hamburger-verzorging'],
          dashboardDesktop: ['menu-dashboard-desktop-hamburger', 'menu-dashboard-desktop-hamburger-verzorging'],
          dashboardMobile: ['menu-dashboard-mobile-hamburger', 'menu-dashboard-mobile-hamburger-verzorging']
        }
      }, {
        label: 'Slapen',
        link: '/baby/slaap',
      }, {
        label: 'Babyspullen',
        link: '/baby/babyspullen',
        tracking: {
          homeDesktop: ['menu-home-desktop-hamburger', 'menu-home-desktop-hamburger-babyspullen'],
          homeMobile: ['menu-home-mobile-hamburger', 'menu-home-mobile-hamburger-babyspullen'],
          dashboardDesktop: ['menu-dashboard-desktop-hamburger', 'menu-dashboard-desktop-hamburger-babyspullen'],
          dashboardMobile: ['menu-dashboard-mobile-hamburger', 'menu-dashboard-mobile-hamburger-babyspullen']
        }
      }, {
        label: 'Kwaaltjes en ziektes',
        link: '/baby/kwaaltjes-en-ziektes',
        tracking: {
          homeDesktop: ['menu-home-desktop-hamburger', 'menu-home-desktop-hamburger-kwaaltjes-en-ziektes'],
          homeMobile: ['menu-home-mobile-hamburger', 'menu-home-mobile-hamburger-kwaaltjes-en-ziektes'],
          dashboardDesktop: ['menu-dashboard-desktop-hamburger', 'menu-dashboard-desktop-hamburger-kwaaltjes-en-ziektes'],
          dashboardMobile: ['menu-dashboard-mobile-hamburger', 'menu-dashboard-mobile-hamburger-kwaaltjes-en-ziektes']
        }
      }, {
        label: 'Praktische zaken',
        link: '/praktische-zaken',
      }, {
        label: 'Opvoeden',
        link: '/baby/opvoeden',
        tracking: {
          homeDesktop: ['menu-home-desktop-hamburger', 'menu-home-desktop-hamburger-opvoeden'],
          homeMobile: ['menu-home-mobile-hamburger', 'menu-home-mobile-hamburger-opvoeden'],
          dashboardDesktop: ['menu-dashboard-desktop-hamburger', 'menu-dashboard-desktop-hamburger-opvoeden'],
          dashboardMobile: ['menu-dashboard-mobile-hamburger', 'menu-dashboard-mobile-hamburger-opvoeden']
        }
      }, {
        label: 'School',
        link: '/school',
        tracking: {
          homeDesktop: ['menu-home-desktop-hamburger', 'menu-home-desktop-hamburger-school'],
          homeMobile: ['menu-home-mobile-hamburger', 'menu-home-mobile-hamburger-school'],
          dashboardDesktop: ['menu-dashboard-desktop-hamburger', 'menu-dashboard-desktop-hamburger-school'],
          dashboardMobile: ['menu-dashboard-mobile-hamburger', 'menu-dashboard-mobile-hamburger-school']
        }
      }]
    }, {
      label: 'Kwaaltjes and klachten',
      link: '/medisch',
      tracking: {
        homeDesktop: ['menu-home-desktop-hamburger', 'menu-home-desktop-hamburger-kwaaltjes-en-klachten'],
        homeMobile: ['menu-home-mobile-hamburger', 'menu-home-mobile-hamburger-kwaaltjes-en-klachten'],
        dashboardDesktop: ['menu-dashboard-desktop-hamburger', 'menu-dashboard-desktop-hamburger-kwaaltjes-en-klachten'],
        dashboardMobile: ['menu-dashboard-mobile-hamburger', 'menu-dashboard-mobile-hamburger-kwaaltjes-en-klachten']
      },
      menu: [{
        label: 'A-Z vragen',
        link: '/medisch',
        tracking: {
          homeDesktop: ['menu-home-desktop-hamburger', 'menu-home-desktop-hamburger-a-z-vragen'],
          homeMobile: ['menu-home-mobile-hamburger', 'menu-home-mobile-hamburger-a-z-vragen'],
          dashboardDesktop: ['menu-dashboard-desktop-hamburger', 'menu-dashboard-desktop-hamburger-a-z-vragen'],
          dashboardMobile: ['menu-dashboard-mobile-hamburger', 'menu-dashboard-mobile-hamburger-a-z-vragen']
        }
      }, {
        label: 'Zwanger worden',
        link: '/zwanger-worden/wat-als-het-niet-lukt',
        tracking: {
          homeDesktop: ['menu-home-desktop-hamburger', 'menu-home-desktop-hamburger-zwanger-worden-kwaaltje'],
          homeMobile: ['menu-home-mobile-hamburger', 'menu-home-mobile-hamburger-zwanger-worden-kwaaltje'],
          dashboardDesktop: ['menu-dashboard-desktop-hamburger', 'menu-dashboard-desktop-hamburger-zwanger-worden-kwaaltje'],
          dashboardMobile: ['menu-dashboard-mobile-hamburger', 'menu-dashboard-mobile-hamburger-zwanger-worden-kwaaltje']
        }
      }, {
        label: 'Zwanger',
        link: '/zwanger/kwaaltjes-en-klachten',
        tracking: {
          homeDesktop: ['menu-home-desktop-hamburger', 'menu-home-desktop-hamburger-zwanger-kwaaltje'],
          homeMobile: ['menu-home-mobile-hamburger', 'menu-home-mobile-hamburger-zwanger-kwaaltje'],
          dashboardDesktop: ['menu-dashboard-desktop-hamburger', 'menu-dashboard-desktop-hamburger-zwanger-kwaaltje'],
          dashboardMobile: ['menu-dashboard-mobile-hamburger', 'menu-dashboard-mobile-hamburger-zwanger-kwaaltje']
        }
      }, {
        label: 'Bevalling',
        link: '/bevalling/complicaties-tijdens-de-bevalling',
        tracking: {
          homeDesktop: ['menu-home-desktop-hamburger', 'menu-home-desktop-hamburger-bevalling-kwaaltje'],
          homeMobile: ['menu-home-mobile-hamburger', 'menu-home-mobile-hamburger-bevalling-kwaaltje'],
          dashboardDesktop: ['menu-dashboard-desktop-hamburger', 'menu-dashboard-desktop-hamburger-bevalling-kwaaltje'],
          dashboardMobile: ['menu-dashboard-mobile-hamburger', 'menu-dashboard-mobile-hamburger-bevalling-kwaaltje']
        }
      }, {
        label: 'Baby',
        link: '/baby/kwaaltjes-en-ziektes',
        tracking: {
          homeDesktop: ['menu-home-desktop-hamburger', 'menu-home-desktop-hamburger-baby-kwaaltje'],
          homeMobile: ['menu-home-mobile-hamburger', 'menu-home-mobile-hamburger-baby-kwaaltje'],
          dashboardDesktop: ['menu-dashboard-desktop-hamburger', 'menu-dashboard-desktop-hamburger-baby-kwaaltje'],
          dashboardMobile: ['menu-dashboard-mobile-hamburger', 'menu-dashboard-mobile-hamburger-baby-kwaaltje']
        }
      }]
    }]).filter(Boolean)
  }
};
