import gql from 'graphql-tag';

export default gql`
  mutation addWishlist(
    $recordKey: String!
  ) {
    addWishlist(
      tableName: "campaign"
      recordKey: $recordKey
      notes: ""
    ) {
      id
    }
  }
`;
