import gql from 'graphql-tag';

export default gql`
  query userGroups {
    userGroups {
      uri
      xuid
      imageUrl
      visibility
      displayName
      accountType

      userGroupTypes {
        groupType {
          code
          name
        }
      }

      journeyPhases {
        journeyPhase {
          id
          name
        }
      }

      members {
        username
        status
      }

      topics {
        xuid
        pinned
        subject
        createdAt
        visibility
        description
        isModerationFlagged

        createdBy {
          displayName
          profilePicture
        }

        follow {
          id
          flagged
          username
          lastSeenAt
        }

        events {
          xuid
          message
          createdAt
          updatedAt
          isModerationFlagged

          createdBy {
            displayName
            profilePicture
          }

          reactions {
            type
            username
            createdAt
          }
        }
      }
    }
  }
`;
