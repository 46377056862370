import gql from 'graphql-tag';

export default gql`
  mutation trackView(
    $contentUri: String!
  ) {
    trackView(
      contentUri: $contentUri
    )
  }
`;
