import { defaultCoursesPage } from 'plaza-react-components/src/helpers/user';

export default user => {
  const isPartner = user.accountType === 'PARTNER';

  return {
    menu: [{
      label: 'Zwanger worden',
      link: '/zwanger-worden',
      menu: [{
        label: 'Kinderwens',
        link: '/zwanger-worden/kinderwens'
      }, {
        label: 'Vruchtbare dagen',
        link: '/zwanger-worden/vruchtbare-dagen-berekenen'
      }, {
        label: 'Ben ik zwanger?',
        link: '/zwanger-worden/ben-ik-zwanger'
      }, {
        label: 'Wat als het niet lukt?',
        link: '/zwanger-worden/wat-als-het-niet-lukt'
      }]
    }, {
      label: 'Zwanger',
      link: '/zwanger',
      menu: [{
        label: 'Zwangerschapskalender',
        link: `${isPartner ? '/zwanger/partnerkalender' : '/zwanger/zwangerschapskalender'}`,
      }, {
        label: 'Uitgerekende datum',
        link: '/zwanger/uitgerekende-datum'
      }, {
        label: 'De babyuitzetlijst',
        link: '/zwanger/babyuitzetlijst'
      }, {
        label: 'Cursusportaal',
        link: defaultCoursesPage(user)
      }, {
        label: 'Gezondheid',
        link: '/zwanger/gezondheid'
      }, {
        label: 'Eten',
        link: '/zwanger/eten-tijdens-de-zwangerschap'
      }, {
        label: 'Zwangerschapschecklist',
        link: '/zwanger/checklist'
      }, {
        label: 'Onderzoeken en controles',
        link: '/zwanger/onderzoeken-en-controles'
      }, {
        label: 'Financiën',
        link: '/zwanger/financien'
      }, {
        label: 'Kwaaltjes and klachten',
        link: '/zwanger/kwaaltjes-en-klachten'
      }]
    }, {
      label: 'Bevalling',
      link: '/bevalling',
      menu: [{
        label: 'Voorbereidingen',
        link: '/bevalling/voorbereidingen'
      }, {
        label: 'Pijnbestrijding',
        link: '/bevalling/pijnbestrijding'
      }, {
        label: 'Alles over weeën',
        link: '/bevalling/alles-over-ween'
      }, {
        label: 'Herstel',
        link: '/herstel',
      }, {
        label: 'Bevallingsverhalen',
        link: '/bevalling/bevallingsverhalen'
      }, {
        label: 'Geboortekaartjes',
        link: '/bevalling/geboortekaartjes-de-mooiste-opties-op-een-rijtje'
      }, {
        label: 'Complicaties',
        link: '/bevalling/complicaties-tijdens-de-bevalling'
      }]
    }, {
      label: 'Baby en kind',
      link: '/baby',
      menu: [{
        label: 'Groeikalender',
        link: '/baby/groeikalender'
      }, {
        label: 'De kraamtijd',
        link: '/baby/de-kraamtijd'
      }, {
        label: 'Borstvoeding',
        link: '/baby/borstvoeding-voor-je-baby',
      }, {
        label: 'Voeding',
        link: '/baby/voeding'
      }, {
        label: 'Verzorging',
        link: '/baby/verzorging'
      }, {
        label: 'Slapen',
        link: '/baby/slaap',
      }, {
        label: 'Babyspullen',
        link: '/baby/babyspullen'
      }, {
        label: 'Kwaaltjes en ziektes',
        link: '/baby/kwaaltjes-en-ziektes'
      }, {
        label: 'Praktische zaken',
        link: '/praktische-zaken'
      }, {
        label: 'Opvoeden',
        link: '/baby/opvoeden'
      }, {
        label: 'School',
        link: '/school'
      }]
    }, {
      label: 'Kwaaltjes and klachten',
      link: '/medisch',
      menu: [{
        label: 'A-Z vragen',
        link: '/medisch'
      }, {
        label: 'Zwanger worden',
        link: '/zwanger-worden/wat-als-het-niet-lukt'
      }, {
        label: 'Zwanger',
        link: '/zwanger/kwaaltjes-en-klachten'
      }, {
        label: 'Bevalling',
        link: '/bevalling/complicaties-tijdens-de-bevalling'
      }, {
        label: 'Baby',
        link: '/baby/kwaaltjes-en-ziektes'
      }]
    }],

    desktopLinks: [{
      items: {
        'Veelgestelde vragen': 'https://www.zwangerenportaal.nl/veelgestelde-vragen-faq',
        'Contact': '/contacts',
        'Over ons': '/over-zp',
        'Adverteren': '/adverteren-op-zwangerenportaal',
        'Werken bij': '/werken-bij-zwangerenportaalnl'
      }
    }],

    mobileLinks: [{
      title: ['Dashboard', '/dashboard'],
      items: {
        'Mijn dossier': '/files',
        'Mijn afspraken': '/appointments',
        'Videoconsult': '/video-call',
        '1-op-1 chat': '/rooms',
        'Gedeelde documenten': '/shared-folders'
      }
    }, {
      title: ['Overige'],
      items: {
        'Veelgestelde vragen': 'https://www.zwangerenportaal.nl/veelgestelde-vragen-faq',
        'Contact': '/contacts',
        'Adverteren': '/adverteren-op-zwangerenportaal',
        'Werken bij': '/werken-bij-zwangerenportaalnl',
        'Over ons': '/over-zp',
        'Algemene voorwaarden': '/algemene-voorwaarden',
        'Privacy statement': '/privacyverklaring',
        'Cookie verklaring': '/cookieverklaring',
        'Kwetsbaarheid melden': '/kwetsbaarheid-melden'
      }
    }]
  };
}
