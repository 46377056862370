import fetch from 'isomorphic-unfetch';
import * as Sentry from '@sentry/browser';
import { onError } from '@apollo/client/link/error';
import { ApolloClient, InMemoryCache, HttpLink } from 'apollo-boost';

import config from '../config';

Sentry.init({ dsn: process.env.SENTRY_DSN });

let apolloClient = null;
const isBrowser = process.browser;

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      Sentry.captureException(
        `[GraphQL error]: Message: ${message},
        Location: ${JSON.stringify(locations)}, Path: ${path}`));
});

const create = initialState => {
  const { jwt } = initialState;

  const httpLink = new HttpLink({
    uri: config.GRAPHQL_URL,
    credentials: 'same-origin',
    fetch: !isBrowser && fetch,

    ...(jwt && {
      headers: { 'X-Authorization': `Bearer ${jwt}` }
    })
  });

  return new ApolloClient({
    connectToDevTools: isBrowser,
    ssrMode: !isBrowser,

    link: errorLink.concat(httpLink),

    cache: new InMemoryCache().restore(initialState || {})
  });
};

export default initialState => {
  if (!isBrowser) return create(initialState);
  if (!apolloClient) apolloClient = create(initialState);

  return apolloClient;
};
