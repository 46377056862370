import gql from 'graphql-tag';

export default gql`
  query metaByPath(
    $path: String!
  ) {
    metaByPath(
      path: $path
    ) {
      title
      keywords
      redirect
      description
      linkedDataJson
    }
  }
`;
