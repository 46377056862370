import gql from 'graphql-tag';

export default gql`
  query {
    me {
      forms(
        userSubmitStatus: false
      ) {
        xuid
        name
        data
        type
        recipient

        formAnswers {
          xuid
          data
          config
        }
      }
    }
  }
`;
