import { useMutation } from '@apollo/client';

import trackViewMutation from '../../requests/mutations/trackView';
import trackSearchMutation from '../../requests/mutations/trackSearch';
import updateLastSeenMutation from '../../requests/mutations/updateLastSeen';

export function useTrackingMutations() {
  const [trackView] = useMutation(trackViewMutation, { ignoreResults: true });
  const [trackSearch] = useMutation(trackSearchMutation, {
    ignoreResults: true
  });
  const [updateLastSeen] = useMutation(updateLastSeenMutation, {
    ignoreResults: true
  });

  return [trackView, trackSearch, updateLastSeen];
}
